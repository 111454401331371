import { Feed } from '@mntn-dev/ui-components'
import { ActivityTrans } from '../activity-trans.tsx'
import { Link } from '../index.ts'
import type { ActivityDetailsProps } from './types.ts'

export const PostProductionCompleted = ({
  activity: {
    details: {
      activityType,
      data: { project },
    },
  },
  t,
  onNavigate,
  currentUrl,
}: ActivityDetailsProps<'post_production_completed'>) => (
  <>
    <Feed.Summary>
      <ActivityTrans
        t={t}
        i18nKey={`${activityType}.summary`}
        values={{
          projectName: project.name,
        }}
      />
    </Feed.Summary>
    <Feed.Annotations>
      <Feed.Tag type="error">{t(`${activityType}.tag`)}</Feed.Tag>
      <Link {...{ target: project, t, onNavigate, currentUrl }} />
    </Feed.Annotations>
  </>
)
